import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class StageThreeService {
  static createStageThree (data) {
    return axios.post(`${url}/app/stage-three/create`, data)
  }
}

export default StageThreeService
